body {
  background:url(../images/ivory-off-white-paper-texture.jpg) center center;
  background-size:cover;
  padding:0;margin:0;font-size:.9em;font-weight:200;
}
h1, h2, h3, h4, p, li, div {
  font-family: 'Roboto Mono', monospace;
  padding:0;margin:0;
}
.main {
  width:80%;margin:40px auto;
  h1 {font-size:2em;}
  h2 {font-size:1.2em;}
  h3 {font-size:1em;}
}
.head {
  .left {
    float:left;width:50%;
  }
  .right {
    float:right;width:50%;
    font-size:1em;text-align:right;
  }
}
.head:after {
  content: "";
  display: table;
  clear: both;
}
.content {
  h2 {margin:20px 0 10px;width:100%;border-bottom:1px solid rgba(0,0,0,.1);border-top:1px solid rgba(0,0,0,.1);}
  ul {padding:0 2em;}
  li {list-style:square;}
}
a {color:#000;text-decoration:none;}
a:hover {text-decoration:underline;}

@media(max-width: 888px) {
  .main {
    width:90%;
  }
}
@media(max-width: 790px) {
  .head {
    .left {
      float:none;width:100%;
    }
    .right {
      float:none;width:100%;
      text-align:left;padding-top:20px;
    }
  }
  
}
